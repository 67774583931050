import { BaseController, LookupsController } from 'Api/controller'
import React, { useEffect } from 'react'
import { useStoreActions } from 'Store'

export const AppInitializer: React.FC = () => {
	const { setHasAccess, setIsLoading } = useStoreActions((action) => action.LoaderModel)
	const { setLookups, setAuth } = useStoreActions((actions) => actions.globalModel)

	useEffect(() => {
		initialize().catch((error) => console.error(error))
		// eslint-disable-next-line
	}, [])

	/* Do app initialization in here.
       Authentication Api, Lookup Api.... Anything that needs to be loaded before the main app
    */
	const initialize = async (): Promise<void> => {
		const baseController = new BaseController()
		const lookupsController = new LookupsController()

		let resp: any = await Promise.allSettled([baseController.getAuthorization(), lookupsController.getLookups()])

		let auth = {
			can_approve: false,
			can_create: false
		}

		if (resp[0].status === 'fulfilled') {
			auth = resp[0].value
			setAuth(auth)
		}

		if (resp[1].status === 'fulfilled') {
			setLookups(resp[1].value)
		}

		setHasAccess(auth.can_approve || auth.can_create)
		setIsLoading(false)
	}

	return <></>
}
