import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
import { Initialization, Logout } from 'TemplateConstants/components'
import reportWebVitals from './reportWebVitals'
import 'Scss/main.scss'

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<StoreProvider store={store}>
				<Switch>
					<Route exact path='/logout' component={Logout} />
					<Route path='/'>
						<Initialization
							loaderProps={{
								contactEmail: 'oel@mit.edu',
								name: 'Elx',
								exit: false
							}}
						/>
					</Route>
				</Switch>
			</StoreProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
