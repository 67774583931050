import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { AtlasPage, BasicLayout } from '@mit/hui'
import { MainNavBar, PendingNavBar, SideNavBar } from 'Components/nav'
import { getEnv } from 'TemplateConstants/common'
import { LoggedInUser } from 'TemplateConstants/components'
import { OpportunityFormDrawer } from 'Components/form'
import { OpportunityFilterForm } from 'Components/filter'
import { ArchivedOpportunities, OpenOpportunities, PendingOpportunities } from 'Components/opportunity'
import { useStoreState } from 'Store'
import Logout from 'Components/Logout'

const App: React.FC = () => {
	const { auth } = useStoreState((store) => store.globalModel)

	const home = (
		<BasicLayout
			contentTitle=''
			startState={true}
			fullWidth
			navigation={<MainNavBar />}
			content={
				<>
					<Route exact path={'/'}>
						<OpenOpportunities />
					</Route>
					<Route exact path={'/archived-opportunities'}>
						<ArchivedOpportunities />
					</Route>
				</>
			}
		/>
	)

	return (
		<Router>
			<>
				<Router>
					<AtlasPage
						gaId={'G-V8EL4PRNDF'}
						theme='ruby'
						environment={getEnv()}
						name={'ELx Admin'}
						navigation={<SideNavBar can_approve={auth.can_approve ?? false} />}
						profile={<LoggedInUser />}
						content={
							<Switch>
								<Route exact path='/'>
									{home}
								</Route>

								<Route exact path='/archived-opportunities'>
									{home}
								</Route>
								<Route exact path={'/logout'}>
									<Logout />
								</Route>
								<Route exact path={'/pending-approval'}>
									<BasicLayout
										contentTitle=''
										startState={true}
										fullWidth
										navigation={<PendingNavBar />}
										content={<PendingOpportunities />}
									/>
								</Route>
							</Switch>
						}
					/>
				</Router>
				<OpportunityFormDrawer />
				<OpportunityFilterForm />
			</>
		</Router>
	)
}

export default App
