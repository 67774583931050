import { Opportunity, OpportunityFormType } from 'Types'

export const getStatusText = (status: string | number | undefined, statusText: string | undefined, has_pending_changes?: boolean): string => {
	// if (has_pending_changes) return 'Pending Changes'
	return statusText ? statusText : 'Live'
	// switch (status) {
	// 	case 'A':
	// 		return 'Approved'
	// 	case 'R':
	// 		return 'Rejected'
	// 	case 'P':
	// 		return 'Pending Approval'
	// 	case 'E':
	// 		return 'Expired'
	// }
	// return 'Live'
}

export const getStatusClass = (status: string | number | undefined, usePendingColor: boolean = false): string => {
	// if (status === 'P' && usePendingColor) {
	// 	return 'status-pending'
	// } else if (status === 'R' || status === 'P' || status === 'E') {
	// 	return 'status-not-approved'
	// }
	if (status === 'P' && usePendingColor) {
		return 'status-pending'
	} else
		switch (status) {
			case 'R':
				return 'status-not-approved'
			case 'P':
				return 'status-pending-orange'
			case 'E':
				return 'status-not-approved'
			case 'CS':
				return 'status-pending-orange'
			case 'CR':
				return 'status-not-approved'
		}
	return 'status-approved'
}

export const getStatusTextClass = (status: string): string => {
	switch (status) {
		case 'A':
			return 'text-success'
		case 'R':
			return 'text-danger'
		default:
			return 'text-pending'
	}
}

export const getTypeText = (type: OpportunityFormType, approve?: boolean): string => {
	let text = ''

	if (type === 'CREATE') {
		text = 'Create'
	} else if (type === 'UPDATE') {
		text = 'Update'
	} else {
		text = 'Approve'
	}

	if (approve) text += ' & Approve'
	else if (!approve && type !== 'APPROVE') text += ` & Request Approval`

	return text
}

export const getStatusType = (status: string | number | undefined, has_pending_changes?: boolean): any => {
	// if (has_pending_changes) return 'warning'

	//if (status === 'A' || status === 'L') return 'success'

	//return 'primary'

	switch (status) {
		case 'A':
			return 'success'
		case 'L':
			return 'success'
		case 'R':
			return 'not-approved'
		case 'P':
			return 'pending'
		case 'E':
			return 'not-approved'
		case 'CS':
			return 'pending'
		case 'CR':
			return 'not-approved'
	}
	return 'primary'
}

export const getTileItems = (items: Opportunity[], handleClick: (row: Opportunity) => void): any => {
	return items.map((row) => {
		let tile_color: any = row.primary_theme?.tile_color || 'blue'
		let number_of_applications = row.number_of_applications

		let data: any = {
			title: row.texts?.title || '',
			subTitles: [
				{
					icon: 'map-marker-alt',
					text: !row.is_remote ? row.location?.city || row.location?.country?.text || 'Remote' : 'Remote'
				},
				{ icon: 'calendar', text: row.terms.map((term) => term.text).join(', ') },
				{ icon: row.primary_theme?.icon, text: '', additionalIcons: row.themes?.map((theme) => theme.icon) }
			],
			backgroundColor: tile_color,
			status: {
				type: getStatusType(row.status?.id, row.has_pending_changes),
				text: getStatusText(row.status?.id, row.status?.text, row.has_pending_changes)
			},
			onClick: () => handleClick(row)
		}
		if (number_of_applications > 0) {
			data.headerInfo = {
				icon: 'user-friends',
				text: row.number_of_applications
			}
		}
		return data
	})
}
