import appConfig from '../app-config'

export const getEnv = () => {
	switch (appConfig.stage) {
		case 'develop':
			return 'dev'
		case 'release':
			return 'staging'
		case 'master':
			return 'production'
	}
	return 'production'
}

export const getNestedValue = (name: string, object: any, editor: any) => {
	let items = name.split('.')

	let object_value = object || {}
	for (let item of items) {
		object_value = object_value !== null ? object_value[item] : object_value
	}
	if (!Object.keys(object).length) return null
	return object_value
}

export const getDateString = (value: string) => {
	if (!value) return 'None'
	value = value.split(' ')[0]
	let [year, month, day] = value.split('-')
	return `${month}/${day}/${year}`
}
