import { action, Action, thunk, Thunk } from 'easy-peasy'
import { getActiveRegion } from 'TemplateConstants/authorization'
import { ActiveRegionResponse } from '@mit/atlas-config/dist.browser'

export interface State {
  hasOutage: boolean
  activeRegionInitialized: boolean
}
// eslint-disable-next-line
interface Actions {
  setHasOutage: Action<this, number>
  setActiveRegionInitialize: Action<this, boolean>
  clearHasOutage: Action<this>
}
// eslint-disable-next-line
interface Thunks {
  checkRegionUpdate: Thunk<this, { config?: ActiveRegionResponse, hasHttpError?: boolean }>
}

export interface IRegionModel extends State, Actions, Thunks {}

const hasActiveOutageTimeout = (): boolean => {
  return new Date().getTime() < (localStorage.getItem('OutageTimeout') ?? 0)
}

export const RegionModel: IRegionModel = {
  hasOutage: hasActiveOutageTimeout(),
  activeRegionInitialized: false,
  // action
  setHasOutage: action((state, timeoutMinutes) => {
    state.hasOutage = true
    // set outage timeout for next x minutes
    localStorage.setItem('OutageTimeout', `${new Date().getTime() + 60000 * timeoutMinutes}`)
  }),
  setActiveRegionInitialize: action((state, payload) => {
    state.activeRegionInitialized = payload
  }),
  clearHasOutage: action(state => {
    if (!hasActiveOutageTimeout()) {
      state.hasOutage = false
      // set outage timeout for next x minutes
      localStorage.removeItem('OutageTimeout')
    }
  }),

  // thunk
  checkRegionUpdate: thunk(async (actions, payload) => {
    const regionConfig = payload.config ?? (await getActiveRegion())

    if (regionConfig.failoverState === 'processing') {
      actions.setHasOutage(5)
    } else if (payload.hasHttpError === true) {
      actions.setHasOutage(2)
    } else {
      actions.clearHasOutage()
    }

    actions.setActiveRegionInitialize(true)
  })
}
