import { BaseController as BaseApiController } from 'TemplateConstants/controller'
import { Authorization } from 'Types/api'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
	readonly apiPath

	constructor() {
		super()
		this.apiPath = this.appConfig.api.paths.elo
	}

	async getAuthorization(): Promise<Authorization> {
		let response = await this.useFetch('GET', 'authorization', `${this.apiHost}/${this.apiPath}/admin/authorization`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}
}
